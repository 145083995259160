import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

import { 
  logoSVGT1, 
    logoSVGT2, 
  calendarSVGT1, 
    calendarSVGT2, 
    calendarSVGT3,
    calendarSVGT4,
    calendarSVGT5,
    calendarSVGT6, 
  locationSVGT, 
} from '../../static/svgtext'
import { 
  ContainerSVGT,
  MainFont,
  SubFont,
  HomeColor, 
  BeliefsColor, 
  EventsColor, 
  LiveColor, 
  SermonsColor, 
  YouthColor, 
  ContactColor, 
  YouthName,
  FourthPage,
  ThirdPage,
} from '../components/Sampler'

export const IndexPageTemplate = ({
  backgroundimage,
  welcometitle,
  welcomeinfo,
  pastorimage,
  pastorinfo,
  weeklycalendar
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
        })`,
      }}
    >
      {/* Google Fonts Import */}
      <link
        href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
        rel="stylesheet"
      ></link>
      <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>

      <div
        style={{
          display: 'flex',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
          width: ''
        }}
      >
        {/* BLANK WHITE CIRCLE SVG */}
        <div style={{height: '300px'}}>
          <div style={{
            position: 'absolute',
            marginTop: '53px',
            width: '100%',
            left: '0px',
            textAlign: 'center',
            fontSize: '2.7em',
            color: HomeColor,
            fontFamily: MainFont,
            textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
            fontWeight: 'bold',
            letterSpacing: '3px',
            lineHeight: '63px'
          }}>
            Eastville<br/>Baptist<br/>Church
          </div>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="310px" viewBox="0 0 1563.000000 1525.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
              fill="#FFFFFF" stroke="none">
              <path d={ContainerSVGT}/>
            </g>
          </svg>
        </div>
      </div>
    </div>

    {/* WELCOME / UPDATE BOX */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: HomeColor,
        display: 'flex',
        justifyContent: 'space-around'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{display: 'flex', marginTop: '20px', marginBottom: '20px'}}>

        {/* LOGO SVG */}
        <div style={{ display: 'grid', margin: '29px', gridTemplateColumns: '208px', height: '206px'}}>

          {/* White Style Container */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="202.72px" viewBox="0 0 1563.000000 1525.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
            }}>

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
              fill='white' stroke="none">
              <path d={ContainerSVGT} />
            </g>
          </svg>

          {/* Logo Svg Image */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="180.72px" viewBox="0 0 445.000000 628.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              margin: 'auto',
              marginBottom: '20'
            }}>

            <g transform="translate(0.000000,628.000000) scale(0.100000,-0.100000)"
              fill={HomeColor} stroke="none">
              <path d={logoSVGT1} />
              <path d={logoSVGT2} />
            </g>
          </svg>
        </div>

        {/* Text */}
        <div className="boxText" style={{margin: '30px'}}>

          {/* Title */}
          <h1 className="title" 
            style={{ 
              fontSize: '2.4em',
              color: 'white',
              fontFamily: MainFont,
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null
            }}
          >
            {/* A Place You Can Call Home */}
            {welcometitle}
          </h1>

          {/* Information */}
          <p style={{
              fontSize: '1em',
              lineHeight: '2em',
              letterSpacing: '0.06em',
              fontFamily: SubFont,
              color: 'white',
            }}
          >
            {/* This is a section of the website where content that can be edited will go. Most of the time, it will contain an introduction and welcome, but if the church has an update or alert, that can go here instead! Normally the title will be the slogan/mission of the church, and the content will be something like "Here at Red Bank Baptist...", but in an update/alert, the title will say something like "New Mask Policy!" or "Last Chance to Sign Up for ___!", and the information below it will explain the update. */}
            {welcomeinfo}
          </p>
        </div>

      </div>
    </div>

    {/* MEET THE PASTOR */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-around'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>

        {/* Text */}
        <div className="boxText" style={{ margin: '30px' }}>

          {/* Title */}
          <h1 className="title"
            style={{
              fontSize: '2.4em',
              color: HomeColor,
              fontFamily: MainFont,
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null
            }}
          >
            Meet the Pastor
          </h1>

          {/* Information */}
          <p
            style={{
              fontSize: '1em',
              lineHeight: '2em',
              letterSpacing: '0.06em',
              fontFamily: SubFont,
              color: HomeColor,
              fontWeight: 'bold'
            }}
          >
            {/* This is a section of the website where content can also be edited, in case of a new pastor. It will have brief info about the current pastor. */}
            {pastorinfo}
          </p>
        </div>

        {/* Pastor Image */}
        <img src={`${!!pastorimage.childImageSharp ? pastorimage.childImageSharp.fluid.src : pastorimage}`} alt="Pastor Image"
          style={{
            height: '200px',
            margin: '30px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }}
        />
        {/* <img src={pastorimage} alt="PastorImage" style={{
          height: '200px',
          margin: '30px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          // alignItems: 'left',
          // marginLeft: '-300px'
        }}
        /> */}
      </div>
    </div>

    {/* A WEEK WITH {CHURCH} */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: HomeColor,
        display: 'flex',
        justifyContent: 'space-around'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{ display: 'flex', marginTop: '20px', marginBottom: '20px' }}>

        {/* CALENDAR SVG */}
        <div style={{ display: 'grid', margin: '29px', gridTemplateColumns: '208px', height: '206px' }}>

          {/* White Style Container */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="202.72px" viewBox="0 0 1563.000000 1525.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
            }}>

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
              fill='white' stroke="none">
              <path d={ContainerSVGT} />
            </g>
          </svg>

          {/* Calendar Svg Image */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="202.72px" viewBox="0 0 443.000000 439.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
              marginLeft: '3px',
              marginTop: '-3px'
            }}>

            <g transform="translate(0.000000,436.000000) scale(0.100000,-0.100000)"
              fill={HomeColor} stroke="none">
              <path d={calendarSVGT1} />
              <path d={calendarSVGT2} />
              <path d={calendarSVGT3} />
              <path d={calendarSVGT4} />
              <path d={calendarSVGT5} />
              <path d={calendarSVGT6} />
            </g>
          </svg>
        </div>


        {/* Text */}
        <div style={{ margin: '30px' }}>

          {/* Title */}
          <h1 className="title"
            style={{
              fontSize: '2.4em',
              color: 'white',
              fontFamily: MainFont,
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null,
            }}
          >
            A Week with Eastville Baptist
          </h1>

          {/* Information */}
          <p
            style={{
              fontSize: '1.1em',
              lineHeight: '2em',
              letterSpacing: '0.06em',
              fontFamily: SubFont,
              color: 'white',
            }}
          >
            {weeklycalendar.map((item) => (
              <div>
                <b>{item.weekday + ', '}</b>
                {item.time + ': '}
                <i>{item.event}</i>
                <br />
              </div>
            ))}
          </p>
        </div>
      </div>
    </div>

    {/* WHERE TO FIND US */}
    {/* Background */}
    <div
      className="index-box margin-top-0"
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '30px',
        // marginBottom: '30px'
      }}
    >
      {/* Content Box */}
      <div className="container" style={{ display: 'flex', marginTop: '40px', marginBottom: '20px' }}>

        {/* Text */}
        <div style={{ textAlign: 'center', margin: '30px' }}>

          {/* Title */}
          <h1 className="title"
            style={{

              fontSize: '2.4em',
              color: HomeColor,
              fontFamily: MainFont,
              textTransform: MainFont === 'Bahnschrift' || MainFont === 'Dosis' ? 'uppercase' : null
            }}
          >
            Where to Find Us
          </h1>

          {/* LOCATION SVG */}
          <div style={{ display: 'grid', justifyContent: 'center', gridTemplateColumns: '208px', height: '206px' }}>

            {/* White Style Container */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              height="202.72px" viewBox="0 0 1563.000000 1525.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{
                gridRow: '1 / 1',
                gridColumn: '1 / 1',
              }}>

              <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                fill={HomeColor} stroke="none">
                <path d={ContainerSVGT} />
              </g>
            </svg>

            {/* Location Svg Image */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              height="202.72px" viewBox="0 0 443.000000 439.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{
                gridRow: '1 / 1',
                gridColumn: '1 / 1',
                marginLeft: '3px',
                marginTop: '-3px'
              }}>

              <g transform="translate(0.000000,436.000000) scale(0.100000,-0.100000)"
                fill='white' stroke="none">
                <path d={locationSVGT} />
              </g>
            </svg>
          </div>

        </div>
        
        {/* Google Maps */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16099.950023458643!2d-75.94754877158431!3d37.35075136093762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ba45aeb0adab99%3A0x38574dfc3424a773!2s5413%20Willow%20Oak%20Rd%2C%20Cape%20Charles%2C%20VA%2023310!5e0!3m2!1sen!2sus!4v1678300102412!5m2!1sen!2sus"
          style={{
            allowfullscreen: 'true',
            loading: 'lazy',
            width: '100%',
            height: '400px',
            border: '0',
            marginLeft: '30px',
            marginRight: '30px'
          }}
        />
      </div>
    </div>

    <section className="section section--gradient">

      <div className="container">
        
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            // width: '70%'
          }}
        >

          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
            
            {/* BELEIFS Link Button */}
            <Link to="/beliefs" title="Beliefs">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >
                
                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={BeliefsColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Beliefs" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontWeight: 'lighter',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  BELIEFS
                </div>
              </div>
            </Link>

            {/* EVENTS Link Button */}
            <Link to="/events" title="Events">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >

                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={EventsColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Events" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  EVENTS
                </div>
              </div>            
            </Link>

            {/* VIDEO Link Button */}
            <Link to="/video" title="Video">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >

                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={LiveColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Live" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  {ThirdPage === "Livestream (Facebook)" || ThirdPage === "Livestream (YouTube)" ? "LIVE" : ThirdPage === "Video (Facebook)" || ThirdPage === "Video (YouTube)" ? "VIDEO" : "HISTORY"}
                </div>
              </div>            
            </Link>

            {/* SERMON Link Button */}
            <Link to="/sermons" title="Sermons">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >

                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={SermonsColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Sermons" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  {FourthPage.toString().toUpperCase()}
                </div>
              </div>            
            </Link>

            {/* YOUTH Link Button */}
            <Link to="/youth" title="Youth">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >

                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={YouthColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Youth" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  {YouthName.toString().toUpperCase()}
                </div>
              </div>            
            </Link>

            {/* CONTACT Link Button */}
            <Link to="/contact" title="Contact Us">
              <div className="link-button" style={{ display: 'grid', maxHeight: '165px', minWidth: '160px', margin: '3px', marginBottom: '7px'}} >

                {/* Blank Style Container */}
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  height="164.8px" viewBox="0 0 1563.000000 1525.000000"
                  preserveAspectRatio="xMidYMid meet" style={{
                    gridRow: '1 / 1',
                    gridColumn: '1 / 1',
                  }}>
                  <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
                    fill={ContactColor} stroke="none">
                    <path d={ContainerSVGT} />
                  </g>
                </svg>

                {/* "Contact" Text */}
                <div style={{
                  gridRow: '1 / 1',
                  gridColumn: '1 / 1',
                  alignSelf: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: 'white',
                  fontFamily: MainFont,
                  fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
                  letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
                }}>
                  CONTACT
                </div>
              </div>            
            </Link>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  welcometitle: PropTypes.string,
  welcomeinfo: PropTypes.string,
  pastorimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pastorinfo: PropTypes.string,
  weeklycalendar: PropTypes.array
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        welcometitle={frontmatter.welcometitle}
        welcomeinfo={frontmatter.welcomeinfo}
        pastorimage={frontmatter.pastorimage}
        pastorinfo={frontmatter.pastorinfo}
        weeklycalendar={frontmatter.weeklycalendar}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        welcometitle
        welcomeinfo
        pastorimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pastorinfo
        weeklycalendar {
          weekday
          time
          event
        }
      }
    }
  }
`
